import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userinfo: null,
    openMenu: false,
    isMobile: false,
  },
  mutations: {
    setUserInfo(state, userinfo) {
      state.userinfo = userinfo
    },
    setOpenMenu(state) {
      state.openMenu = !state.openMenu
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile
    }
  },
  actions: {
  },
  modules: {
  }
})
