import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'


Vue.use(VueRouter)

  const routes = [
	{
		path: '/',
		name: 'Layout',
		component: Layout,
		redirect: '/home',
		children: [
			{
				path: '/home',
				name: 'Home',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "login" */ '../views/Home')
			},
			{
				path: '/corporateInformation',
				name: 'CorporateInformation',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "corporateInformation" */ '../views/corporateInformation/index')
			},
			{
				path: '/corporateInformation/changePassword',
				name: 'ChangePassword',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/corporateInformation/ChangePassword')
			},
			{
				path: '/backmsg',
				name: 'backmsg',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/message/backmsg')
			},
			{
				path: '/corporateInformation/packagePurchase',
				name: 'PackagePurchase',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/corporateInformation/PackagePurchase')
			},
			{
				path: '/template',
				name: 'Template',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/template/index')
			},
			{
				path: '/kehu',
				name: 'kehu',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/kehu/index')
			},
			{
				path: '/rili',
				name: 'rili',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/kehu/rl')
			},
			{
				path: '/autoTemplate',
				name: 'Template',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/template/autotemplate')
			},
			{
				path: '/guanfang',
				name: 'gfTemplate',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/template/guanfang')
			},
			{
				path: '/huodong',
				name: 'Huodong',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/template/huodong')
			},
			{
				path: '/tuijian',
				name: 'tuijian',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/tuijian/index')
			},
			{
				path: '/tongyong/:sjk',
				name: 'Template',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/tongyong/index')
			},
			{
				path: '/renwulist',
				name: 'Renwulist',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/renwulist/index')
			},
			{
				path: '/pendingList',
				name: 'PendingList',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/pendingList/index')
			},		
			{
				path: '/waitset/:pageid',
				name: 'waitset',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/wait/waitset')
			},		
			{
				path: '/messenger',
				name: 'messenger',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/wait/messenger')
			},
			{
				path: '/chart',
				name: 'chart',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/chart/chart')
			},
			{
				path: '/jiaohao',
				name: 'jiaohao',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/wait/jiaohao')
			},					
			{
				path: '/wlogin',
				name: 'loginwhatsapp',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/wait/wlogin')
			},			
			{
				path: '/chart',
				name: 'chart',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/chart/chart')
			},
			{
				path: '/financialManagement',
				name: 'rechargeRecord',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/financialManagement/rechargeRecord')
			},
			{
				path: '/invoiceRecord',
				name: 'invoiceRecord',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "PackagePurchase" */ '../views/financialManagement/invoiceRecord')
			},
		]
	},
	{
		path: '/login',
		name: 'Login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "login" */ '../views/login')
	}
]

const router = new VueRouter({
  routes
})

export default router
