<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
	created() {
		this.isMobile()
	},
	methods: {
		isMobile() {
			if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
				this.$store.commit('setIsMobile', true)
			}else{
				this.$store.commit('setIsMobile', false)
			}
		}
	}
}
</script>

<style lang='scss'>
  @import '@/assets/scss/app.scss';
  html,body,#app{
    width: 100%;
    height: 100%;
  }
</style>
