<template>
    <div class="layout-wrapper">
        <left-menu />
        <div class="fixed-top flex-end">
            <div class="menu flex-start" @click="changeMenu">
                <i class="el-icon-s-unfold"></i>
            </div>
			<div><el-button @click="gobuy" type="warning" round>購買服務</el-button></div>
			<div style="width: 10px;"></div>
			<div><el-button @click="dialogVisible=true" type="info" round>預約教學</el-button></div>
				<div style="width: 10px;"></div>
			<div  @click="quit"><el-button type="danger" round>登出</el-button></div>
			
			
            <div class="language username">
				
   <!--             <el-dropdown>
                    <span class="el-dropdown-link">
                      {{users.username}}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item  > <span @click="quit"> 退出 </span></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
				 -->
				
            </div>    
<!-- 			<div class="language">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        {{locale}}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in locales" :key="item.value"> <span @click="changeLanguage(item.value)"> {{item.label}}</span></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div> -->
        </div>
        <div class="alert" v-if="$store.state.openMenu" @click="changeMenu"></div>
        <div class="content">
            <router-view :key="this.$route.fullPath" />
        </div>
<!--        <div class="boot">
            <span> </span>
        </div> -->
		
		
		<!-- add renwu-->
		<el-dialog
		  title="預約教學"
		  :visible.sync="dialogVisible"
		  :width="$store.state.isMobile? '90%' :'556px'"
		  :before-close="handleClose"
		>
		  <div class="add-template">
		    <el-form ref="form" :model="form">
				  
				  
				  <el-form-item label="預約日期">
				   <el-date-picker
				         v-model="form.plantime"
				         type="date"
				         placeholder="选择日期时间">
				       </el-date-picker>
				    </el-form-item>
				     
		 
				   
				   
				<el-form-item label="關注問題">
				  <el-input v-model="form.title"   :rows="1"></el-input>
				</el-form-item>
				
	 
		  
		 
		 		 
				 <el-form-item    label="聯係人">
				   <el-input v-model="form.msg1"   :rows="2"></el-input>
				 </el-form-item>
		 
				 <el-form-item    	label="聯係電話">
				   <el-input v-model="form.msg2"   :rows="2"></el-input>
				 </el-form-item>
		 
				 
		    </el-form>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
		    <el-button type="primary" @click="sure">{{
		      $t("Sure")
		    }}</el-button>
		  </span>
		</el-dialog>
		
		
    </div>
</template>
<script>
import LeftMenu from "./components/LeftMenu";
export default {
    components: { LeftMenu },
    data() {
        return {
			form: {
			    type: this.$t('typewhatsapp'),
			    title: '',
			    text: '',
			    qyid: '',
			    status: '',
			    pic: ''
			},
			dialogVisible:false,
			users:[],
            locale: 'EN', //使用i18n实例默认定义的语言
            locales: [
                {
                    value: "EN",
                    label: "English",
                },
                {
                    value: "zh-CN",
                    label: "中文简体",
                },
                {
                    value: "zh-HK",
                    label: "中文繁體",
                },
            ],
        };
    },
	created() {
		this.getset()
        const locale = window.localStorage.getItem('locale') || this.$i18n.locale
        this.locale = locale
        this.$i18n.locale = locale
	},
    methods: {
		handleClose(){
			this.dialogVisible=false 
		},
        changeLanguage(value) {
            this.locale = value
            window.localStorage.setItem('locale', value)
            window.location.reload()
        },
		getset(){
			this.users = JSON.parse(window.localStorage.getItem('userinfo'))
			if(this.users.length==0){
				//	this.$router.push('/login');
			}
			console.log(this.users);
		},
		gobuy(){
		   this.$router.push('/corporateInformation/packagePurchase');	
		},
		quit(){
			const formData={}
			window.localStorage.removeItem('user')
			window.localStorage.removeItem('userinfo')
			 
			this.$router.push('/login');
			
		},
        changeMenu() {
            this.$store.commit('setOpenMenu')
        },
		sure(){
			this.$message({type:'success',message:'已預約'})
			this.dialogVisible=false
		}
    },
    watch: {
        // locale(val) {
        //     this.$i18n.locale = val
        // },
    },
};
</script>
<style lang="scss" scoped>
	.username {
		margin: 10px;
	}
    .layout-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding-left: 200px;
        padding-top: 50px;
        .content {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            padding: 30px;
        }
        .boot {
            width: 100%;
            height: 10%;
            -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
            box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
            padding: 0px;
        }
        .fixed-top {
            height: 50px;
            overflow: hidden;
            position: relative;
            background: #fff;
            -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
            box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            z-index: 1;
            padding-right: 30px;
        }
        .menu{
            position: absolute;
            width: 50px;
            height: 50px;
            left: 30px;
            bottom: 0;
            display: none;
            cursor: pointer;
            i{
                font-size: 24px;
            }

        }
        .el-dropdown-link{
            cursor: pointer;
        }
        .alert{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.5);
            z-index: 40;
            transition: all .6s;
        }
    }
    @media screen and (max-width: 768px){
        .layout-wrapper{
            padding-left: 0px;
            padding-top: 30px;
            .menu{
                display: flex;
            }
        }
    }
</style>