import Vue from 'vue'
import App from './App.vue'
import router from './router'
import echarts from 'echarts'
import store from './store'
import i18n from './i18n'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import FBSignInButton from 'vue-facebook-signin-button'
 
Vue.use(FBSignInButton)

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$echarts = echarts

var user = JSON.parse(window.localStorage.getItem('user')) || ''
var userinfo = JSON.parse(window.localStorage.getItem('userinfo')) || ''
if (!user || !userinfo) {
    window.location.hash = '#/login'
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
