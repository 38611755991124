<template>
    <div class="menu-wrapper" :class="[$store.state.openMenu?'active':'']">
        <el-menu
            class="el-menu-vertical-demo"
            background-color="#324155"
            text-color="#fff"
            router
            :default-active="$route.path"
            @select="selectMenu"
            active-text-color="#409EFF">
			    <span></span>
				<span class="logtitle">Nowait-System</span>
				  <el-menu-item key="home" index="home">
				        <i class="el-icon-s-marketing"></i>
				        <span slot="title">總覽</span>
				  </el-menu-item>
			  <el-menu-item key="home4" index="pendingList">
				        <i class="el-icon-collection"></i>
				        <span slot="title">會員資料庫</span>
				  </el-menu-item>

            <el-submenu   :index="index+''" v-for="(item,index) in menu" :key="index">
                <template    slot="title">
					<div   :style="item.color==1?'background-color:coral;margin-left: -12%;margin-right: -12%;':''"> 
                    <i  :style="item.color==1?'margin-left: 10%;':''" class="el-icon-menu"></i>
                    <span  >{{item.label}}</span>
					</div>
                </template>
                <el-menu-item-group  >
                    <el-menu-item  v-for="child in item.children" :key="child.path" :index="child.path">{{child.label}}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
export default {
    data() {
        return {
            menu: [
                // {
                //     label: this.$t('overview'),
                //     children: [
                //         {label: this.$t('Home'), path: '/home'}
                   
                      
                //     ]
                // },
                {
                    label: 'WhatsApp推廣',
             
                    children: [
					
                        {label: this.$t('TemplateList'), path: '/template'},
                        {label: this.$t('guanfangTemplateList'), path: '/guanfang'},
                        {label:  this.$t('renwulist'), path: '/renwulist'},
						{label:  this.$t('renwuhis'), path: '/tongyong/messenger_sendlist'}
                      
                    ],
					color:0
                },
				{
				    label:  '拓客推薦獎賞', 
				    children: [
				      
				        {label: this.$t('huodonglist'), path: '/huodong'}
				    
				    ],
					color:0
				}, 
                {
                    label: 'Whatsapp客服',
                    children: [
                         {label: this.$t('autoTemplate'), path: '/autoTemplate'},
                        {label: this.$t('kehulist'), path: '/kehu'},
						   {label:  this.$t('backmsglist') , path: '/backmsg'}
                    ],
					color:0
                },

				{
                    label:  this.$t('waitsystem') ,
                    children: [
                      
                        {label:  this.$t('qiyelist') , path: '/messenger'},
                        {label:  this.$t('datasys')  , path: '/chart'},
                    
                        // {label: '叫号系统', path: '/jiaohao'},
                    ],
					color:0
                },		
				{
				    label: 'WhatsShop',
				             
				    children: [
					
				     
				       
				     	{label:  this.$t('Productlist'), path: '/tongyong/messenger_product'},
						{label:  this.$t('orderlist'), path: '/tongyong/messenger_orderlist'}
				      
				    ],
					color:0
				},
	 
                {
                    label: this.$t('FinancialManagement'),
                    children: [
                        {label: this.$t('rechargeRecord'), path: '/financialManagement'},
                        // {label: this.$t('invoiceRecord'), path: '/invoiceRecord'},
					    {label: this.$t('PackagePurchase'), path: '/corporateInformation/packagePurchase'},
					    // {label: this.$t('tuijian'), path: '/tuijian'}
                    ],
				
                },
				{
					 label: '設定',
					 children: [
					     {label: this.$t('quite'), path: '/login'},
						 {label: this.$t('ModifyData'), path: '/corporateInformation'},
						 {label: this.$t('changePassword'), path: '/corporateInformation/changePassword'}
					   
					 ]
				}
            ]
        }
    },
    methods: {
        selectMenu() {
            if(this.$store.state.isMobile){
                this.$store.commit('setOpenMenu')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
	.child-item .el-menu-item{
	    background-color: #1F2D3D!important;
	  }
	  
	 .el-submenu {
	  background-color: #1F2D3D!important;
	}
	.logtitle {
		color: aliceblue;
		margin-top: 30px;
		margin: 20px;
		height: 30px;
		line-height: 30px;
	}
    .menu-wrapper{
        width: 200px;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        transition: all .6s;
        .el-menu-vertical-demo{
            height: 100%;
        }
    }
    @media screen and (max-width: 768px){
        .menu-wrapper{
            transform: translateX(-400px);
            z-index: 50;
            &.active{
                transform: translateX(0px);
            }
        }
        ::v-deep .el-menu{
            border-right: none;
        }
    }
</style>